import React, { useEffect, useState } from "react";
import css from './TipMap.module.css'

import GoogleMapReact from 'google-map-react';
import { Modal, TipCard } from "../../../../components";
import outdoorAndAdventureIcon from './Outdoor&Adventure.png';
import clubsAndNightlifeIcon from './clubs-nightlife_icon.png';
import restaurantIcon from './restaurant_icon.png';
import historicalLandmarkIcon from './historical-landmark_icon.png';
import surfingIcon from './surfing_icon.png';
import hikingIcon from './hiking_icon.png';
import beachIcon from './beach_icon.png';
import skiingIcon from './skiing_icon.png';
import vanspotIcon from './vanspot_icon.png';
import waterfallIcon from './waterfall_icon.png';
import PhotographicSpotIcon from './photography_spot.png';
import cafeIcon from './cafe_icon.png';
import kitesurfIcon from './kitesurf_icon.png';
import streetfoodIcon from './streetfood_icon.png';

const TipMap = props => {
    const { tips, showGuideMap, onManageDisableScrolling } = props;
    const [center, setCenter] = useState(null);
    const [zoom, setZoom] = useState(18);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [initializedMapZoom, setInitializedMapZoom] = useState(false);

    const iconMapping = {
        "Outdoor & Adventures": outdoorAndAdventureIcon,
        "Restaurants": restaurantIcon,
        "Music Clubs": clubsAndNightlifeIcon,
        "Historical landmark": historicalLandmarkIcon,
        "Surf Spot": surfingIcon,
        "Trekking & Hiking": hikingIcon,
        "Skiing": skiingIcon,
        "Beach": beachIcon,
        "Van Spot": vanspotIcon,
        "Waterfall & Rivers": waterfallIcon,
        "Photographic Spot": PhotographicSpotIcon,
        "Cafés": cafeIcon,
        "Kitesurf Spot": kitesurfIcon,
        "Streetfood": streetfoodIcon,
    };

    useEffect(() => {
        if (tips && tips.length > 0) {
            if (selectedIndex !== null) {
                setCenter({
                    lat: tips[selectedIndex].location.selectedPlace.origin.lat,
                    lng: tips[selectedIndex].location.selectedPlace.origin.lng,
                });
            } else {
                let latTotal = 0;
                let lngTotal = 0;
                const noOfTips = tips.length;
                tips.forEach(tip => {
                    latTotal += tip.location.selectedPlace.origin.lat;
                    lngTotal += tip.location.selectedPlace.origin.lng;
                });

                setCenter({
                    lat: latTotal / noOfTips,
                    lng: lngTotal / noOfTips,
                });
            }
        }
    }, [tips, selectedIndex]);

    const onClickTip = (index) => {
        setSelectedIndex(index);
        setIsModalOpen(true);
    };

    const onChange = ({ bounds, marginBounds }) => {
        if (!initializedMapZoom) {
            let allTipsVisible = true;
            tips.forEach(tip => {
                const lat = tip.location.selectedPlace.origin.lat;
                const lng = tip.location.selectedPlace.origin.lng;
                const isInBounds = checkIfCoordinatesAreInbounds(lat, lng, bounds);
                if (!isInBounds) {
                    allTipsVisible = false;
                }
            });
            if (allTipsVisible) {
                setInitializedMapZoom(true);
            }
            setZoom(zoom - 1);
        }
    };

    const checkIfCoordinatesAreInbounds = (lat, lng, bounds) => {
        let isInBounds = true;
        if ((lat > bounds.ne.lat) || (lat < bounds.sw.lat)) {
            isInBounds = false;
        } else if ((lng > bounds.ne.lng) || (lng < bounds.sw.lng)) {
            isInBounds = false;
        }
        return isInBounds;
    };

    return (
        <>
            <p>Number of places <span className={css.numberOfTips}>{tips?.length || 0}</span></p>
            <div style={{ height: '400px', width: '100%', position: 'relative' }}>
                {!showGuideMap && <div className={css.overlayNotPurchased}>
                    <div className={css.overlayText}>
                        <span>Purchase to view</span>
                    </div>
                </div>}
                {zoom && center && <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                    center={center}
                    zoom={zoom}
                    onChange={onChange}
                    options={{
                        styles: [
                            {
                                featureType: 'poi',
                                elementType: 'labels',
                                stylers: [{ visibility: 'off' }] // This will hide points of interest
                            },
                            {
                                featureType: 'poi.park',
                                elementType: 'labels',
                                stylers: [{ visibility: 'off' }] // This will hide parks
                            },
                            {
                                featureType: 'transit',
                                elementType: 'labels.icon',
                                stylers: [{ visibility: 'off' }] // This will hide transit icons
                            },
                        ],
                        streetViewControl: showGuideMap,
                        fullscreenControl: showGuideMap,
                        zoomControl: showGuideMap,
                        gestureHandling: !showGuideMap && 'none',
                    }}
                >
                    {tips.map((tip, index) => {
                        const icon = iconMapping[tip.category];

                        return <div
                            key={tip.location.selectedPlace.name + index}
                            lat={tip.location.selectedPlace.origin.lat}
                            lng={tip.location.selectedPlace.origin.lng}
                            onClick={() => showGuideMap && onClickTip(index)}
                        >
                            {!!icon
                                ? <img className={css.labelIcon} src={icon} />
                                : <div className={css.labelWrapper}>
                                    <img className={css["icon-image"]} src={tip.location.selectedPlace.icon} />
                                </div>}
                        </div>;
                    })}
                </GoogleMapReact>}
            </div>
            <Modal
                id="TipModal"
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                }}
                containerClassName={css.tipModal}
                contentClassName={css.tipModalContent}
                usePortal
                lightCloseButton
                onManageDisableScrolling={onManageDisableScrolling}
            >
                {(selectedIndex !== null) && <TipCard
                    place={tips[selectedIndex]}
                    className={css.tipCard}
                />}
            </Modal>
        </>
    );
};

export default TipMap;
