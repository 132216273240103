import React, { useEffect } from "react";

import css from './TipsSection.module.css'
import { useDispatch, useSelector } from "react-redux";
import { checkIfGuideMapIsPurchased } from '../ListingPage.duck';
import TipMap from "./TipMap/TipMap";

const TipsSection = props => {
    const dispatch = useDispatch()

    const { currentListing, isOwnListing, onManageDisableScrolling } = props
    const state = useSelector(state => state)
    const { user, ListingPage: listingPageReducer } = state || {}
    const currentUserId = user?.currentUser?.id.uuid
    const isLoggedIn = !!currentUserId

    const { showGuideMap, tips } = listingPageReducer || {}

    useEffect(() => {
        if (!isOwnListing) {
            dispatch(checkIfGuideMapIsPurchased(currentListing.id.uuid, null, null, isLoggedIn))
        } else if (isOwnListing) {
            dispatch(checkIfGuideMapIsPurchased(currentListing.id.uuid, isOwnListing, currentListing.author.id.uuid))
        }
    }, [currentUserId])


    return <div className={css.root}>
        <TipMap
            tips={tips}
            showGuideMap={showGuideMap}
            onManageDisableScrolling={onManageDisableScrolling}
        />
    </div>
}

export default TipsSection